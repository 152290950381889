<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2 ">
            <i class="text-primary ti ti-school small-gap-right" />
            <h4 class="text-primary mb-0">Daftar Kelas</h4>
          </div>

          <!-- Table of Student-->
          <div>
            <b-row>
              <!-- Display Row -->
              <b-col lg="1">
                <b-form-select
                  class="w-auto mr-1"
                  v-model="pageLength"
                  :options="options"
                />
              </b-col>

              <!-- Table Filter -->
              <b-col></b-col>
              <b-col lg="6">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Filter</label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block mr-1"
                    />

                    <div class="schedule-filter small-gap-right">
                      <v-select
                        v-model="selectedSchedules"
                        :options="schedules"
                      />
                    </div>

                    <!-- add button -->
                    <b-button
                      variant="primary"
                      class="d-flex"
                      @click="addStudyYear"
                    >
                      <i class="ti ti-plus small-gap-right"></i>
                      Tambah
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Table -->
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Customize Column -->

                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'classroomName'"
                  class="text-nowrap"
                >
                  <b-avatar variant="light-primary" class="mx-1">
                    <i class="ti" :class="props.row.classroomIcon"></i>
                  </b-avatar>
                  <span class="font-weight-bolder text-nowrap">{{
                    pascalCase(props.row.classroomName)
                  }}</span>
                </span>

                <!-- Column: Action -->
                <span
                  class="d-flex justify-content-start"
                  v-else-if="props.column.field === 'action'"
                >
                  <b-button
                    variant="warning"
                    size="sm"
                    class="mr-1"
                    @click="handleEdit(props.row)"
                  >
                    <i class="ti ti-pencil" />
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    class="mr-1"
                    @click="handleDelete(props.row)"
                  >
                    <i class="ti ti-trash" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ pascalCase(props.formattedRow[props.column.field]) }}
                </span>
              </template>

              <!-- Pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      hidden
                      class="w-auto mr-1"
                      :options="options"
                      v-model="pageLength"
                      @input="
                        props.perPageChanged({ currentPerPage: pageLength })
                      "
                    />
                    <span class="text-nowrap ">
                      Showing
                      {{
                        pageLength <= props.total
                          ? pageLength * currentPage
                          : props.total
                      }}
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          currentPage = value
                          props.pageChanged({ currentPage: value })
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// *Component
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// *Utils
import axios from 'axios'
import { pascalCase } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VueGoodTable,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Initial Data
      classroomId: this.$router.currentRoute.params.id,

      // Filter
      schedules: [
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu',
        'Minggu',
        'Semua',
      ],
      selectedSchedules: 'Semua',
      selectedContent: 'Pertemuan 1',
      studentPresenceStatusFilter: 'Semua',

      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 5,
      options: ['5', '10', '30'],
      columns: [
        {
          label: 'Nama Kelas',
          field: 'classroomName',
        },
        {
          label: 'Pengajar',
          field: 'teacher',
        },
        {
          label: 'Jumlah Siswa',
          field: 'studentCount',
        },
        {
          label: 'Jadwal',
          field: 'schedule',
          width: '15%',
        },
        // {
        //   label: 'Aksi',
        //   field: 'action',
        //   width: '5%',
        // },
      ],
      rows: [
        {
          classroomIcon: 'ti-calendar-stats',
          classroomName: 'Sejarah',
          teacher: 'Pak Brams',
          studentCount: '20',
          schedule: 'Senin',
        },
      ],
    }
  },
  async created() {
    const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }

    await axios
      .get(
        `${baseUrl}/teachers/classrooms/${this.classroomId}/students`,
        config
      )
      .then((response) => {
        const students = response.data.data

        const listOfStudents = students.map((student) => {
          return {
            id: student.student_id,
            avatar: require('@/assets/images/avatars/10-small.png'),
            // fullName: pascalCase(student.student.name),
            fullName: student.student_id,
            task: '1/3',
          }
        })

        this.rows = listOfStudents
      })
      .catch((error) => {
        console.error(error)
      })
  },
  methods: {
    triggerModal() {
      this.$bvModal.show('content-add-modal')
    },
    pascalCase,
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.line-numbers {
  padding: 0 1.2em !important;
}

.schedule-filter {
  min-width: 150px !important;
}
</style>
